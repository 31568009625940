import React, { Component } from 'react';

class Portfolio extends Component {
  render() {

    if(this.props.data){
      var projects = this.props.data.projects.map(function(projects){
        var projectImage = 'images/portfolio/'+projects.image;
        return <div key={projects.title} className="columns portfolio-item">
           <div className="item-wrap">
            <a href={projects.url} title={projects.title} target="_blank" rel="noopener noreferrer">
               <img alt={projects.title} src={projectImage} />
               <div className="overlay">
                  <div className="portfolio-item-meta">
                     <h5>{projects.title}</h5>
                     <p>{projects.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
          <h5 style={{color:"white", textShadow: '0px 1px 3px rgba(0, 0, 0, .8)'}}>{projects.title.length > 37 ? projects.title.substring(0,37) + "..." : projects.title}</h5>
        </div>
      })
    }

    return (
      <section id="portfolio">

      <div className="row">
         <div className="twelve columns collapsed">
             <h2 style={{color:'white', textShadow: '0px 1px 3px rgba(0, 0, 0, .8)', paddingBottom:10}}>Looking to hire?</h2>
             <p>
                 <a href={'https://cv.matthewkempa.co.uk/'} className="button" target='_blank' rel="noopener noreferrer">Check out my CV</a>
             </p>

            <h2 style={{color:'white', textShadow: '0px 1px 3px rgba(0, 0, 0, .8)', paddingBottom:10}}>Looking for some of my work?</h2>
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {projects}
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Portfolio;
