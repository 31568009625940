import React, { Component } from 'react';
import Portfolio from "./Portfolio";

class Header extends Component {
  render() {
    return (
      <header id="home" style={{height:'auto !important'}}>

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">Welcome!</h1>
            <hr />

         </div>
          <Portfolio data={this.props.portfolio}/>
      </div>


   </header>
    );
  }
}

export default Header;
